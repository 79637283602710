
.transaction-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    flex-wrap: wrap;
    gap: 10px;
}

.transaction-head span {
    margin-right: 8px;

    a {
        word-break: break-all;
    }
}

.veto-signed {
    color: green;
}

.veto-needed {
    color: red;
}

.sigs-complete {
    color: green;
}

.sigs-required {
    color: purple;
}

.transaction-head .token-line {
    flex: 1;
}

.transaction-head .sigs {

}

.tx-status-success {
    color: green;
}

.tx-status-failed {
    color: red;
}

.transaction-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: 700px) {
        gap: 10px;
    }
}

.transaction-body .details {
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;

    @media (max-width: 700px) {
        padding: 4px;
        gap: 10px;
    }
}

.transaction-body .data-pair {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    word-break: break-all;
     gap: 10px;
    @media (max-width: 700px) {
        gap: 10px;
    }
}

.transaction-body .right-side {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;

    @media (max-width: 700px) {
        padding: 4px
    }
}

.transaction-body .right-side .item {
    margin-bottom: 8px;
}

.transaction-body .attach-tx {
    color: chocolate;
}



