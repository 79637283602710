
.card-accordoin {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 2px solid;
    padding: 4px;
    border-radius: 5px;
    margin-bottom: 4px;
}

.card-accordoin .row1 {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: 600px) {
        height: auto;
    }
}

.card-accordoin .row1-content {
    flex: 1;
}


.card-accordoin .rest {
    min-height: 200px;
}

.card-accordoin .expand {
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
